import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import http from "@/api.js";
const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('../views/index.vue')
  },
  {
    path: '/pcHome',
    name: 'pcHome',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/pcHome.vue')
  },
  {
    path: '/phoneHome',
    name: 'phoneHome',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/phoneHome.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from, next) => {
  if (to.path == '/pcHome' || to.path == '/phoneHome') {
    if (localStorage.getItem("password")) {
      http.get("check_pass", { pass: localStorage.getItem("password") }).then((res) => {
        if (res.code == 0) {
          next({ path: "/" })
        } else {
          next()
        }
      });
    } else {
      next({ path: "/" })
    }
  } else {
    next()
  }

})




export default router
