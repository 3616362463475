<template>
  <router-view />
</template>

<script setup>
import { onMounted } from "vue";

function init() {
  getFontSize();
}

window.onresize = function () {
  // console.log("改变窗口");
  aa();
};
const checkOs = () => {
  const ua = navigator.userAgent,
    isWindowsPhone = /(?:Windows Phone)/.test(ua),
    isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
    isAndroid = /(?:Android)/.test(ua),
    isFireFox = /(?:Firefox)/.test(ua),
    isChrome = /(?:Chrome|CriOS)/.test(ua),
    isTablet =
      /(?:iPad|PlayBook)/.test(ua) ||
      (isAndroid && !/(?:Mobile)/.test(ua)) ||
      (isFireFox && /(?:Tablet)/.test(ua)),
    isPhone = /(?:iPhone)/.test(ua) && !isTablet,
    isPc = !isPhone && !isAndroid && !isSymbian;
  return {
    isTablet: isTablet,
    isPhone: isPhone,
    isAndroid: isAndroid,
    isPc: isPc,
  };
};
function aa() {
  const os = checkOs();
  if (os.isAndroid || os.isPhone) {
    getFontSize(375);
  } else if (os.isTablet) {
    // console.log("平板");
    getFontSize(1920);
  } else if (os.isPc) {
    // console.log("电脑");
    getFontSize(1920);
  }
}
function getFontSize(num) {
  console.log();
  let a = document.documentElement.clientWidth || document.body.clientWidth;
  localStorage.setItem("width", a);
  document.documentElement.style.fontSize = (a / num) * 1 + "px";
}

onMounted(() => {
  setInterval(() => {
    if (
      new Date().getHours() == 0 &&
      new Date().getMinutes() == 0 &&
      new Date().getSeconds() == 0
    ) {
      location.reload();
    }
  }, 1000);
  init();
  aa();
});
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html,
body {
  /* margin: 100rem !important; */
  padding: 0;
  width: 100%;
  /* min-height: 100vh; */
  background-color: #02021f;
  color: #fff;
  overflow-x: hidden;
}
td {
  all: unset;
}
ul {
  list-style: none;
}
a {
  text-decoration: none;
}
#app,
#nav {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
.main {
  margin: 15rem auto 0 auto;
  padding: 15rem;
}
.flex {
  display: flex;
  align-items: center;
}

.j_c_b {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.j_c_c {
  display: flex;
  align-items: center;
  justify-content: center;
}

div {
  box-sizing: border-box;
  font-size: 14rem;
}
.fs12 {
  font-size: 12rem;
  line-height: 12rem;
}
.fs16 {
  font-size: 16rem;
  line-height: 16rem;
}
.fs18 {
  font-size: 18rem;
  line-height: 18rem;
}
.fs20 {
  font-size: 20rem;
  line-height: 20rem;
}
.fs24 {
  font-size: 24rem;
}

.fs26 {
  font-size: 26rem;
}
.fs30 {
  font-size: 30rem;
  line-height: 30rem;
}
.fs40 {
  font-size: 40rem;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.c44FEFD {
  color: #44fefd;
}
::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}
</style>
