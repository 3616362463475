import axios from "axios";

const instance = axios.create({
        timeout: 30000,
        headers: {
                "Content-Type": "application/json",
                accept: "application/json",
        },
        baseURL: "/api/pc/"
});

// 请求拦截器
instance.interceptors.request.use(
        (config) => {
                return config;
        },
        (error) => {
                return new Promise.reject(error);
        }
);

// 响应拦截器：根据状态码判断登录状态、授权
instance.interceptors.response.use(
        (response) => {
                switch (response.status) {
                        case 201:
                                message.success("新增成功");
                                break;
                        case 204:
                                message.success("成功");
                                break;
                        default:
                                break;
                }
                return response;
        },
        async (error) => {
                return Promise.reject(error);
        }
);

const http = {
        get(url, data) {
                return new Promise(function (resolve, reject) {
                        instance
                                .get(url, { params: data })
                                .then((res) => {
                                        resolve(res && res.data);
                                })
                                .catch((e) => {
                                        reject(e);
                                });
                });
        },
        post(url, data) {
                return new Promise(function (resolve, reject) {
                        instance
                                .post(url, data)
                                .then((res) => {
                                        resolve(res.data);
                                })
                                .catch((e) => {
                                        reject(e);
                                });
                });
        },
        del(url, data) {
                return new Promise(function (resolve, reject) {
                        instance
                                .delete(url, data)
                                .then((res) => {
                                        resolve(res.data);
                                })
                                .catch((e) => {
                                        reject(e);
                                });
                });
        },
        put(url, data) {
                return new Promise(function (resolve, reject) {
                        instance
                                .put(url, data)
                                .then((res) => {
                                        resolve(res.data);
                                })
                                .catch((e) => {
                                        reject(e);
                                });
                });
        },
}

export default http;